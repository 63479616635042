





































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    showDialog: {
      default: false,
      type: Boolean
    },
    value: {
      default: 0,
      type: Number
    },
    image: {
      default: "",
      type: String
    }
  },
  setup(props, { emit }) {
    const title = "Please use the scanner within the TNG mobile App to scan the QR Code below";

    function handleCancel() {
      emit("update:showDialog", false);
      emit("onCancel");
      return;
    }

    return { title, handleCancel };
  }
});
